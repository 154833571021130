.card-grid {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    row-gap: 44px;

    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr;
        gap: 44px;
    }
    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 69px;
    }

    .card {
        display: flex;
        align-items: stretch;
        width: 100%;
    }
    .no-results {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-column: 1 / -1;
        text-align: center;
        padding: 32px 0;

        h4 {
            font-size: 1rem;
        }
        img {
            width: 100%;
            max-width: 300px;
        }
    }
}