.login-providers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    .btn-provider {
        box-sizing: border-box;
        display: flex;
        flex-grow: 1;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        gap: 8px;
        border: 1px solid #F2F2F2;
        border-radius: 3px;
        font-family: $fontMontserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $navyBlue;
    }
}

.login-providers-or {
    font-family: $fontMontserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #242424;
    margin: 24px 0;
}

.login-mfa-options {
    .form-check {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}