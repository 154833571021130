$masonry-item-size: 194px;

.masonry {
    display: flex;
    width: 100%;
}

.masonry--h, 
.masonry--v {
    margin-left: -18px;
    counter-reset: brick;
}

.masonry--h {
    flex-flow: row wrap;
}

.masonry-item {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 0 18px 18px;
    width: $masonry-item-size;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    .masonry-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 4px;
    }
    .texts-on-hover {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        background: linear-gradient(180.59deg, rgba(51, 51, 51, 0) -11.75%, #333333 118.76%);
        opacity: 0;
        pointer-events: none;
        transition: opacity .2s ease-in;

        h6 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $white;
            margin: 0;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $white;
            margin: 0;
        }
    }
    &:hover {
        .texts-on-hover {
            opacity: 1;
            transition: opacity .2s ease-out;
        }
    }
}

.masonry-item--width2 {
    width: $masonry-item-size * 2;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.masonry-item--h {
    flex: auto;
    height: $masonry-item-size;
    min-width: $masonry-item-size;
}
