.cystals-intro-1 {
    padding: 126px 0 168px 0;
    background-image: url('./../images/crystals-page/intro-background.png');
    background-repeat: no-repeat;
    background-position: bottom;
    
    .crystals-intro-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 16px;

        .crystals-intro-texts {
            max-width: 828px;
            margin: 0 auto;

            h1 {
                font-style: normal;
                font-weight: 700;
                font-size: 64px;
                line-height: 80px;
                text-align: center;
                margin-bottom: 16px;
                color: $white;
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                text-align: center;
                color: $white;
            }
        }
    }
}

.crystals-model-section {
    padding: 60px 0 140px 0;

    .threed-model {
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }
    h2 {
        margin-top: 16px;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
    }
    p {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: $navyBlue;
    }
}

.bg-crystals-carousel {
    background-color: #17253F;
    background-image: url('./../images/crystals-page/carousel-bg-glow.png');
    background-repeat: no-repeat;
    background-position: top -120px center;
}

.crystals-carousel-section {
    padding: 90px 0;

    @include media-breakpoint-up(xl) {
        padding: 90px;
    }

    .crystals-carousel {
        .carousel-main {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 52px;
            padding: 0px;

            @include media-breakpoint-up(md) {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 0px;
                margin-bottom: 100px;
            }

            .carousel-controls-wrap {
                @include media-breakpoint-down(sm) {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    gap: 32px;
                }

                .carousel-control-next, 
                .carousel-control-prev {
                    position: relative;
                    display: block;
                    width: 64px;
                    height: 64px;

                    @include media-breakpoint-up(md) {
                        position: absolute;
                        top: 216px;
                    }
                    @include media-breakpoint-up(lg) {
                        top: 316px;
                    }
                }
                @include media-breakpoint-up(xl) {
                    .carousel-control-next {
                        right: 90px;
                    }
                    .carousel-control-prev {
                        left: 90px;
                    }
                }
            }
            .carousel-inner {
                max-width: 512px;

                @include media-breakpoint-up(lg) {
                    max-width: 828px;
                }

                .carousel-item {
                    padding: 0px;

                    &> img {
                        margin: 0 auto;
                        margin-bottom: 24px;
                        max-width: 600px;
                    }
                    .carousel-caption {
                        position: relative;
                        right: auto;
                        bottom: auto;
                        left: auto;
                        padding: 0px 0px 10px 0px;

                        h5 {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 38px;
                            line-height: 60px;
                            text-align: center;
                            color: #D9D9D9;
                            margin: 0 0 16px 0;
                        }
                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #FFFFFF;
                            margin-bottom: 24px;
                        }
                        .btn {
                            padding: 16px 24px;
                        }
                    }
                }
            }
        }
        .carousel-indicators {
            position: relative;
            display: none;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 60px;
            margin: 0;

            @include media-breakpoint-up(md) {
                display: flex;
            }

            button {
                width: 160px;
                height: 160px;
                background-color: transparent;
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-radius: 4px;

                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}

.crystals-info {
    padding: 90px 0;

    @include media-breakpoint-up(lg) {
        padding: 90px;
    }

    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        margin: 0;
    }
    &> p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #444444;
        margin-top: 32px;

        a {
            color: $emeraldGreen;
            font-weight: bold;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.crystalline-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    padding: 56px 0 90px 0;
    
    @include media-breakpoint-up(lg) {
        padding: 56px 90px 90px 90px;
    }

    .gallery-head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 28px;
        max-width: 660px;
        margin: 0 auto;

        h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 72px;
            line-height: 90px;
            text-align: center;
            color: #000000;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: $navyBlue;
        }
    }
}

.crystalline-gallery-expl {
    padding: 36px;
    border: 1px solid $silverBlue;
    border-radius: 4px;
    margin: 0 .5rem 6.875rem .5rem;

    @include media-breakpoint-up(lg) {
        margin: 0 5.625rem 6.875rem 5.625rem;
    }

    .texts-col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 28px;

        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 38px;
            text-align: left;
            color: $black;
            margin: 0;

            @include media-breakpoint-up(md) {
                font-size: 36px;
                line-height: 44px;
            }
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: $navyBlue;
            margin: 0;

            @include media-breakpoint-up(md) {
                font-size: 20px;
                line-height: 30px;
            }
        }
        .btn {
            @include media-breakpoint-down(sm) {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .crystalline-animation {
        height: 130px;
        width: 330px;
        margin-top: 72px;
        border-radius: 90px;
        overflow: hidden;

        @include media-breakpoint-up(xl) {
            height: 180px;
            width: 466px;
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}