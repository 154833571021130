.bars-hero-background {
    background-image: none;

    @include media-breakpoint-up(md) {
        background-image: url('./../images/bars-page/hero-background.png');
        background-repeat: no-repeat;
        background-size: 45%;
        background-position: right 0 top 40%;
    }
    @include media-breakpoint-up(lg) {
        background-size: 55%;
        background-position: right 0px top 20px;
    }
    @include media-breakpoint-up(xl) {
        background-size: 669px 605px;
        background-position: right 0px top 20px;
    }
}

.bars-hero-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90px 0px;
    gap: 48px;

    @include media-breakpoint-up(md) {
        align-items: flex-start;
        padding: 90px 48px;
    }
    @include media-breakpoint-up(lg) {
        padding: 130px 48px 220px 48px;
    }

    .btn {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }
}

.bars-hero-texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 16px;

    @include media-breakpoint-up(md) {
        align-items: flex-start;
    }

    h1 {
        font-style: normal;
        font-weight: 700;
        text-align: center;
        font-size: 64px;
        line-height: 80px;
        color: $white;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }
    p {
        font-style: normal;
        font-weight: 400;
        text-align: center;
        font-size: 24px;
        line-height: 32px;
        color: #AEAEAE;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }
}

.bars-intro-1 {
    padding: 90px 0 0 0;

    @include media-breakpoint-up(md) {
        padding: 90px 0 0 0;
    }
    @include media-breakpoint-up(lg) {
        padding: 90px 90px 0 90px;
    }

    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 16px;
        max-width: 828px;
        margin: 0 auto;

        h2 {
            margin: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 60px;
            text-align: center;
            color: $navyBlue;
        }
        &> p {
            margin: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #434343;
        }
    }
}

.bars-row {
    margin: 0 auto;
    padding: 0;

    @include media-breakpoint-down(xl) {
        width: 100%;
        max-width: none;
    }

    img {
        object-fit: cover;
        aspect-ratio: 16 / 9;

        @include media-breakpoint-up(lg) {
            aspect-ratio: auto;
        }
    }
}

.bars-details {
    padding: 90px 0px 140px 0px;

    @include media-breakpoint-up(md) {
        padding: 90px 40px 140px 40px;
    }
    @include media-breakpoint-up(lg) {
        padding: 90px 90px 140px 90px;
    }

    .bars-details-text {
        font-family: $fontMontserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #434343;
        padding-right: 12px;
    }
    .tags {
        margin-top: 42px;
    }
    .btn {
        margin-top: 32px;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-top: 80px;
            width: auto;
        }
    }
    .details-img {
        width: 100%;
        border-radius: 4px;
    }
}

.bars-engraving-bg {
    background-image: none;

    @include media-breakpoint-up(md) {
        background-image: url('./../images/bars-page/engraving-background.png');
        background-repeat: no-repeat;
        background-position: left;
        min-height: 460px;
    }
}

.bars-engraving {
    .bars-engraving-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 68px 0;
        gap: 32px;

        @include media-breakpoint-up(md) {
            padding: 100px 40px;
        }
        @include media-breakpoint-up(lg) {
            padding: 42px 90px;
        }

        .bars-engraving-texts {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            gap: 16px;

            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 60px;
                color: $white;
                text-align: center;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #AEAEAE;
                text-align: center;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }
            }
        }
        .btn {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: auto;
            }
        }
    }
}