.profile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 36px;

    &> .nav-pills {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;
        padding: 12px;
        gap: 8px;
        flex: 288px 0 0;
        background: $white;
        border: 1px solid #F2F2F2;
        border-radius: 4px;

        .nav-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 12px;
            gap: 12px;
            width: 100%;
            border-radius: 4px;
            font-family: $fontMontserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #787878;

            .icon-stroke {
                stroke: #787878;
            }

            &.active {
                background: $silverBlue;
                color: $navyBlue;

                .icon-stroke {
                    stroke: $navyBlue;
                }
            }
        }
    }
    .tab-content {
        position: relative;
        flex-grow: 1;

        h3 {
            margin-bottom: 16px;
        }
        .tab-pane {
            &.show {
                min-height: 200px;
            }
            &.loading {
                &::after {
                    content: "Loading...";
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    background-color: $white;
                    text-align: center;
                }
            }
        }
    }
}

.orders {
    .orders-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 12px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        .orders-filters {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 12px;
            flex: none;
            order: 0;
            flex-grow: 1;

            .nav-pills {
                gap: 12px;

                .nav-item {
                    .nav-link {
                        background: #FFFFFF;
                        border: 1px solid #F2F2F2;
                        border-radius: 999px;
                        font-family: $fontMontserrat;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: $navyBlue;

                        &:hover {
                            background: #F2F2F2;
                        }
                        &.active {
                            background: #E1EAE9;
                        }
                    }
                }
            }
        }
        .orders-search {
            .input-group {
                background: #FFFFFF;
                border: 1px solid #F2F2F2;
                border-radius: 4px;

                .form-control {
                    background-color: transparent;
                    border: none;
                    padding: 8px 12px;
                }

                .form-control::placeholder {
                    color: #CDCDCD;
                }

                .input-group-btn {
                    button[type=submit]:not(.btn-sm), 
                    input[type=submit]:not(.btn-sm) {
                        line-height: 24px;
                        padding: 5px 10px;
                    }
                }
            }
        }
    }
}

.mfa-header {
    position: relative;
    padding: 1.25rem 1.25rem 0 1.25rem;

    .mfa-options-btn {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;

        .dropdown-toggle {
            padding: 0 4px 0 4px;

            &.dropdown-toggle::after {
                display: none;
            }
        }

        .link {
            font-size: 18px !important;
        }
    }
}

.mfa-footer {
    padding: 0 1.25rem 1.25rem 1.25rem;

    svg {
        margin-top: -4px;
        margin-right: 6px;
        width: 16px;
        height: 16px;
        color: #CCC;
        fill: #CCC;
    }
}

.mfa-if-enabled {
    display: none;
}

.mfa-if-disabled {
    display: block;
}

.mfa-enabled {
    border-color: rgb(71, 184, 129) !important;

    .mfa-footer {
        background-color: rgb(241, 250, 245);
        color: rgb(82, 189, 149);
        padding: 1.25rem;

        svg {
            color: rgb(71, 184, 129);
            fill: rgb(71, 184, 129);
        }
    }

    .mfa-if-enabled {
        display: block;
    }

    .mfa-if-disabled {
        display: none;
    }
}

.mfa-enable-box {
    margin-bottom: 60px;
}