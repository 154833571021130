.page-box-shadow {
    margin: 4rem 0px;
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 4px;

    @include media-breakpoint-up(lg) {
        margin: 6rem 0px;
        padding: 24px;
        background: #FFFFFF;
        box-shadow: 2px 5px 44px rgba(0, 0, 0, 0.05);
    }

    .box-heading {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        text-align: center;
        margin: 32px 4rem;

        h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: $navyBlue;
            margin: 0;
        }
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #242424;
            margin: 0;
        }
    }
    .box-tabs {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin-bottom: 1.5rem;
        gap: 32px;
        border-bottom: 2px solid #D2D2D2;

        .nav-item {
            flex-grow: 1;

            .nav-link {
                display: block;
                width: 100%;
                padding: 14px 20px 18px 20px;
                font-family: $fontMontserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #D2D2D2;
                background-color: transparent;
                border-bottom: 2px solid transparent;
                border-radius: 0px;
                margin-bottom: -2px;

                &.active {
                    font-weight: 700;
                    color: $navyBlue;
                    border-bottom: 2px solid $navyBlue;
                }
            }
        }
    }
    .box-body {
        margin: 0 1rem;
        
        @include media-breakpoint-up(md) {
            margin: 0 4rem;
        }
    }
    .btn-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 16px;
        margin-top: 32px;
    }
}

.message-box {
    padding: 32px 0px;
    text-align: center;

    @include media-breakpoint-up(md) {
        padding: 32px 114px;
    }

    &> svg {
        width: 48px;
        height: 48px;
        margin-bottom: 32px;
    }
    &> h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #242424;
        margin-bottom: 32px;
    }
    &> p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #242424;
        margin-bottom: 32px;
    }
}

.error-box {
    padding: 200px 0px;

    &> h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 80px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 16px;
    }
    &> p {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 48px;
    }
}