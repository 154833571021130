.contact-us {
    background-image: url('./../images/navyblue-osmium-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $navyBlue;
    border-radius: 8px;

    .information {
        margin: 0;
        padding: 20px;

        @include media-breakpoint-up(md) {
            margin: 20px 26px;
            padding: 40px;
        }
        @include media-breakpoint-up(lg) {
            margin: 32px 56px;
        }

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: $white;
            margin: 0;
        }
        &> p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #DEDEDE;
            margin: 16px 0 0 0;
        }
        .contacts {
            margin: 40px 0 0 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;

            @include media-breakpoint-up(md) {
                gap: 16px;
            }

            .contact {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 6px;
                gap: 8px;
                border-radius: 3px;

                @include media-breakpoint-up(md) {
                    padding: 16px;
                    gap: 24px;
                }

                svg {
                    width: 24px;
                    height: 24px;

                    @include media-breakpoint-up(md) {
                        width: 32px;
                        height: 32px;
                    }
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #B9D1FD;
                    margin: 0;

                    @include media-breakpoint-up(md) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                a {
                    color: #B9D1FD;
                    text-decoration: none;

                    &:hover {
                        color: shade-color(#B9D1FD, 10%);
                    }
                }
            }
        }
    }
    .contact-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        padding: 24px 0;
        margin: 0px;
        background: $white;
        border-radius: 0px;

        @include media-breakpoint-up(md) {
            padding: 24px;
            margin: 32px;
            border-radius: 8px;
        }
        @include media-breakpoint-up(lg) {
            margin: 32px 56px;
        }

        .form-group {
            width: 100%;
            margin: 0;
        }
    }
}