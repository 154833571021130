footer {
    background-color: $navyBlue;
    margin-top: $vertical-section-spacing-sm !important;

    @include media-breakpoint-up(md) {
        margin-top: $vertical-section-spacing-md !important;
    }
    @include media-breakpoint-up(lg) {
        margin-top: $vertical-section-spacing-lg !important;
    }

    .footer-wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: $max-body-width;
        margin: 0 auto;
        padding: 66px 2.75rem 24px 2.75rem;
        gap: 24px;

        @include media-breakpoint-up(md) {
            padding: 66px 1.75rem 24px 1.75rem;
        }

        .footer-1 {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 36px;
            padding-bottom: 50px;

            @include media-breakpoint-up(md) {
                display: grid;
                grid-auto-rows: 1fr;
                grid-template-columns: 1fr 1fr;
                align-items: flex-start;
                gap: 0px;
            }

            .footer-left {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 36px;

                .footer-logo {
                    display: block;

                    .logo {
                        fill: #FFFFFF;
                    }
                }
                .footer-language-select {
                    .btn {
                        padding: 8px 28px;
                        font-family: $fontMontserrat;
                        font-size: 16px;
                        font-weight: 700;
                    }
                    .dropdown-item.active, 
                    .dropdown-item:active {
                        background-color: transparent;
                    }
                }
                .footer-accepted-payments {
                    .label {
                        font-family: $fontMontserrat;
                        font-size: 14px;
                        font-weight: 600;
                        color: #78808F;
                    }
                    .payment-icons {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 16px;
            
                        &> svg {
                            fill: #FFFFFF;

                            &.sofort {
                                height: 20px;
                                margin-left: -8px;
                            }
                        }
                    }
                }
            }
            .footer-menu-grid {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                gap: 32px;
                padding-top: 20px;

                @include media-breakpoint-up(md) {
                    display: grid;
                    grid-auto-rows: 1fr;
                    grid-template-columns: 1fr 1fr 1fr;
                    align-items: flex-start;
                    gap: 0px;
                }

                .footer-menu {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 12px;

                    .footer-menu-title {
                        color: $white;
                        text-align: left;
                        font-family: $fontMontserrat;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 27px; /* 135% */
                    }
                    a {
                        font-family: $fontOpenSans;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        text-decoration: none;
                        color: #78808F;

                        &:hover {
                            color: shade-color(#78808F, 25%);
                        }
                    }
                }
            }
        }
        .footer-separator {
            width: 100%;
            height: 2px;
            background: rgba(255, 255, 255, 0.09);
        }
        .footer-2 {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            padding: 24px 0;

            @include media-breakpoint-up(md) {
                display: grid;
                grid-auto-rows: 1fr;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: flex-start;
                gap: 0px;
            }

            .footer-wrap {
                max-width: $max-body-width;
                margin: 0 auto;
                padding: 1rem 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                gap: 32px;

                @include media-breakpoint-up(md) {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 12px;
                }
            }
            .footer-menu {
                display: grid;
                grid-template-columns: 1fr;
                text-align: center;
                padding: 0px;

                @include media-breakpoint-up(md) {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: 16px;
                    text-align: left;
                }

                a {
                    font-family: $fontMontserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    text-decoration: none;
                    color: #78808F;

                    &:hover {
                        color: shade-color(#78808F, 25%);
                    }
                }
            }
            .footer-social {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 16px;

                @include media-breakpoint-up(md) {
                    justify-content: center;
                }
                @include media-breakpoint-up(lg) {
                    gap: 32px;
                }

                a {
                    font-size: 32px;
                    color: #FFFFFF;
                    text-decoration: none;

                    @include media-breakpoint-up(md) {
                        font-size: 22px;
                    }

                    &:hover {
                        color: shade-color(#FFFFFF, 25%);
                    }
                }
            }
            .footer-copyright {
                font-family: $fontOpenSans;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                text-align: center;
                color: #78808F;
                margin: 0;

                @include media-breakpoint-up(md) {
                    text-align: right;
                }
            }
        }
    }
}