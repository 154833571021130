.badge {
    display: inline-block;
    font-family: $fontMontserrat;
    font-size: 16px;
    line-height: 24px;
    margin: 0 6px 10px 0;
}

.badge-primary {
    background: #E1E8F4;
}

.badge-success {
    background: #D5F6EC;
}

.badge-warning {
    background: #F4EEE1;
}