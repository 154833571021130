header {
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #F2F2F2;
    
    .navbar {
        max-width: $max-body-width;
        margin: 0 auto;
        padding: 1.5rem 1.75rem;

        @include media-breakpoint-up(lg) {
            padding: .75rem 2.25rem;
        }

        .navbar-brand {
            img {
                width: 46px;
            }
        }
        .navbar-collapse {
            @include media-breakpoint-down(lg) {
                padding: 20px 0 0 0;
            }
        }
        .navbar-nav {
            .nav-item {
                padding: 0rem;

                @include media-breakpoint-up(xl) {
                    padding: 0rem .75rem;
                }
                &.nav-item-mobile {
                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }
                &.nav-item-desktop {
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                }
                .nav-link {
                    font-family: $fontMontserrat;
                    font-size: 16px;
                    font-weight: 600;
                    color: $navyBlue;
                    padding: 0.5rem 0.5rem;

                    @include media-breakpoint-up(lg) {
                        padding: 0.5rem 1rem;
                    }
                    &.active {
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                    &.dropdown-toggle {
                        &::after {
                            margin-left: 0.75em;
                        }
                    }
                }
            }
            &.navbar-nav-mobile {
                border-top: 1px solid #f2f2f2;
                margin-top: 10px;
                margin-bottom: 0;
                padding-top: 10px;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }
        .right-side,
        .mobile-right-menu {
            display: none;
            flex-direction: row;
            justify-content: flex-end;

            @include media-breakpoint-up(lg) {
                display: flex;
            }

            .icon-nav {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
                gap: 4px;
                
                .nav-item {
                    padding: 0;
                
                    .nav-link {
                        position: relative;
                        border: 0px;
                        padding: 6px;
                        text-decoration: none;

                        .cart-counter {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: 0 1px 0 0;
                            gap: 10px;
                            position: absolute;
                            width: 13px;
                            height: 13px;
                            left: 22px;
                            top: 6px;
                            background: #D60E0E;
                            border-radius: 999px;
                            font-family: $fontOpenSans;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 10px;
                            line-height: 13px;
                            color: $white;

                            &.hide {
                                display: none;
                            }
                        }
                        .lang-indicator {
                            display: block;
                            position: absolute;
                            width: 13px;
                            height: 13px;
                            left: 22px;
                            top: 6px;
                            border-radius: 999px;

                            &> svg {
                                vertical-align: top;
                                width: 12px;
                                height: 12px;
                            }
                        }
                        &.dropdown-toggle {
                            &::after {
                                vertical-align: .2em;
                                margin-left: .1em;
                                margin-right: 0.5em;
                                border-top: 0.3em solid;
                                border-right: 0.3em solid transparent;
                                border-bottom: 0;
                                border-left: 0.3em solid transparent;
                            }
                            &.show {
                                &::after {
                                    border-top: 0;
                                    border-right: 0.3em solid transparent;
                                    border-bottom: 0.3em solid;
                                    border-left: 0.3em solid transparent;
                                }
                            }
                        }
                    }
                }
            }
            .buttons {
                .btn {
                    line-height: 1.75rem;
                    border: 1px solid $navyBlue;
                    border-radius: 3px;
                    padding: 0.25rem 1.5rem;
                }
                .btn:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
        .mobile-right-menu {
            display: flex;
            flex-grow: 1;
            padding-right: 12px;
            
            @include media-breakpoint-up(lg) {
                display: none !important;
            } 
        }
        .wallet-connected-pane {
            display: none;
            
            .view-wallet-btn {
                display: inline-block;
                padding: 5px 14px 5px 44px !important;

                &> svg {
                    position: absolute;
                    top: 7px;
                    left: 9px;
                }
                .wallet-address-text {
                    font-family: $fontMontserrat;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    color: $navyBlue;
                }
            }
            .wallet-info-dropdown {
                padding: 12px;

                .wallet-info-title {
                    font-family: $fontMontserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    color: #BFBFBF;
                }
                .wallet-address-text {
                    position: relative;
                    font-family: $fontMontserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: $navyBlue;
                    margin-bottom: 0;
                    margin-top: 8px;
                    padding-left: 16px;

                    &::before {
                        content: '';
                        display: block;
                        height: 7px;
                        width: 7px;
                        background: #3E8B65;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 0px;
                        transform: translateY(-50%);
                    }
                }
                .wallet-info-buttons {
                    margin: -4px;
                    margin-top: 20px;

                    .btn {
                        padding: 0.25rem 0.5rem;
                    }
                }
            }
        }
    }
    .products-collapse-content,
    .learn-collapse-content {
        padding: 12px 0;
        background: #FFFFFF;
        border-top: 1px solid #F2F2F2;

        &:not(.mobile) {
            @include media-breakpoint-down(lg) {
                display: none !important;
            } 
        }
        &.mobile {
            border-bottom: 1px solid #F2F2F2;

            @include media-breakpoint-up(lg) {
                display: none !important;
            } 
        }
        .products-menu {
            max-width: $max-body-width;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: .5rem 1.5rem;
            gap: 10px;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
                padding: 0 0 0 116px;
                gap: 40px;
            }

            .products-menu-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 8px;
                gap: 12px;
                text-decoration: none;
                flex-grow: 1;
                width: 100%;

                @include media-breakpoint-up(lg) {
                    flex-grow: 0;
                    width: auto;
                }

                &> img {
                    border-radius: 4px;
                }
                .texts {
                    min-width: 132px;
                    overflow: hidden;
                    max-height: 24px;
                    transition: max-height 0.3s ease-out;

                    .heading {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 0;
                        white-space: nowrap;
                    }
                    &> .link {
                        font-family: $fontMontserrat;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: $navyBlue;
                    }
                }
                &:hover {
                    background: #FAFAFA;

                    .texts {
                        max-height: 500px;
                        transition: max-height 0.3s ease-in;
                    }
                }
            }
        }
        &.mobile {
            .products-menu {
                padding: 0px;
            }
        }
    }
}