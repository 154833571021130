.bg-navyblue {
    background-color: $navyBlue;
}

.bg-dark-jungle-green {
    background-color: $darkJungleGreen;
}

.bg-alabaster {
    background-color: #FAFAFA;
}

.bg-lightgray {
    background-color: $lightGray;
}

.bg-osmium-shards {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 150%;
    background: linear-gradient(0deg, rgba(28, 28, 28, 0.55) 0%, rgba(0, 0, 0, 0) 50%), url('./../images/misc/osmium-shards.webp');
}

.badge-success {
    color: $emeraldGreen;
    background: #EAFBF6;
}

.badge-warning {
    color: #605E2A;
    background: #F5F4CD;
}

.badge-secondary {
    color: #7D7D7D;
    background: #F3F3F3;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

.loading-overlay {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: shade-color($body-bg, 1%);

    .overlay__inner {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;

        .overlay__content {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);

            .spinner {
                width: 75px;
                height: 75px;
                display: inline-block;
                border-width: 2px;
                border-color: rgba(255, 255, 255, 0.05);
                border-top-color: $navyBlue;
                animation: spin 1s infinite linear;
                border-radius: 100%;
                border-style: solid;
            }
            
            @keyframes spin {
              100% {
                transform: rotate(360deg);
              }
            }
        }
    }
}

body > .loading-overlay {
    position: fixed;
    background-color: rgba(252, 252, 252, .6);
}

.error-container {
    width: 100%;
}

.carousel-control-prev,
.carousel-control-next {
    &:hover {
        .carousel-control-prev-icon {
            background: escape-svg($carousel-control-prev-icon-bg-hover);
        }
        .carousel-control-next-icon {
            background: escape-svg($carousel-control-next-icon-bg-hover);
        }
    }
}