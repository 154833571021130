.modal-message {
    position: relative;
    background: #D0E1F5;

    &> p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $midnightBlue;
        padding: 16px 20px;
        margin: 0;

        @include media-breakpoint-up(md) {
            padding: 16px 160px;
        }
        @include media-breakpoint-up(lg) {
            padding: 16px 310px;
        }
    }
    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        gap: 6px;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            display: inline-flex;
            width: auto;
            vertical-align: middle;
            position: absolute;
            top: 12px;
            right: 32px;
            padding-bottom: 0px;
        }

        .btn {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: $midnightBlue;
            border: none;
            outline: none;
            padding: 5px 24px;
            transition: all .2s ease-in-out;

            &:hover {
                color: #D0E1F5;
                background-color: $midnightBlue;
            }
        }
        .btn-outlined {
            border: 1px solid $midnightBlue;
            border-radius: 3px;
        }
    }
}

.termsfeed-com---nb-simple {
    position: fixed !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
    max-width: 100% !important;
}

.termsfeed-com---nb .cc-nb-main-container {
    padding: 1rem !important;
}

.termsfeed-com---palette-light.termsfeed-com---nb {
    background: #d0e1f5 !important;
    color: $midnightBlue !important;
    text-align: center;
}

.termsfeed-com---palette-light .cc-nb-title,
.termsfeed-com---palette-light .cc-nb-text {
    font-family: $fontMontserrat;
    color: $midnightBlue !important;
}

.termsfeed-com---nb .cc-nb-title {
    font-size: 20px !important;
    margin-bottom: 0.75rem !important;
}

.termsfeed-com---nb .cc-nb-text {
    font-size: 14px !important;
    margin: 0 0 1.0rem 0 !important;
}

.termsfeed-com---nb .cc-nb-okagree, 
.termsfeed-com---nb .cc-nb-reject, 
.termsfeed-com---nb .cc-nb-changep {
    font-family: $fontMontserrat;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 18px !important;
}

.termsfeed-com---palette-light .cc-nb-okagree,
.termsfeed-com---palette-light .cc-nb-reject,
.termsfeed-com---palette-light .cc-nb-changep {
    color: $midnightBlue !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.termsfeed-com---palette-light .cc-nb-okagree,
.termsfeed-com---palette-light .cc-nb-reject {
    border: 1px solid $midnightBlue !important;
    border-radius: 3px !important;
}

.cc-nb-buttons-container {
    button {
        padding: 5px 24px !important;
        transition: all .2s ease-in-out;

        &:hover {
            color: #D0E1F5 !important;
            background-color: $midnightBlue !important;
        }
    }
}