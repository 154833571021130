.legal-notice {
    .page-title {
        margin: 130px 0 98px 0;
    }
    .company-details {
        margin-bottom: 33px;

        h3, p {
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 33px;
            color: #17253F;
        }
        p {
            font-weight: 500;
            font-size: 20px;
        }
    }
    .text-content {
        list-style: none;
        padding: 0;

        h5, p {
            margin-bottom: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 33px;
            color: #939393;
        }
        p {
            font-weight: 400;
            margin-bottom: 33px;
        }
    }
}