.about-us {
    .page-intro-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-self: stretch;
        height: auto;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    
        .text-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 16px;
            align-self: stretch;
            width: auto;
            padding: 32px 0;

            @include media-breakpoint-up(md) {
                gap: 36px;
                padding: 48px 0;
            }
            @include media-breakpoint-up(lg) {
                width: 420px;
                padding: 48px 26px;
            }
            @include media-breakpoint-up(xl) {
                padding: 48px;
            }

            h1 {
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 52px; /* 126.316% */
                hyphens: auto;
                word-wrap: break-word;
                overflow-wrap: break-word;
                margin: 0;
            }
            p {
                color: $independenceGray;
                font-family: $fontOpenSans;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 34px;
                margin: 0;
            }
        }
        .carousel {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 5px;
            flex: 1 0 0;
            align-self: stretch;
            padding: 8px 0 0 0;
            max-height: 600px;

            .carousel-indicators {
                top: 0px;
                bottom: initial;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 6px;
                align-self: stretch;
                margin: 0;

                [data-bs-target] {
                    flex: 1 0 0;
                    height: 3px;
                    background: $blueNcs;
                    margin: 0;
                    border: none;
                }
            }
        }
    }
    .the-vision {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 60px 0 $vertical-section-spacing-sm 0;

        @include media-breakpoint-up(md) {
            padding: $vertical-section-spacing-md 0;
        }
        @include media-breakpoint-up(lg) {
            flex-direction: row;
            justify-content: space-between;
            padding: 200px 0 $vertical-section-spacing-lg 0;
        }

        .image-block {
            margin-top: 32px;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
        .text-block {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 36px;
            flex-shrink: 0;

            @include media-breakpoint-up(lg) {
                width: 500px;
            }

            &> p {
                color: $independenceGray;
                font-family: $fontOpenSans;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 36px; /* 200% */
                margin: 0;

                &> span {
                    color: $blueNcs;
                    text-decoration: underline;
                    vertical-align: text-bottom;
                }
            }
        }
    }
    .the-team {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 36px;
    }
    .people-cards {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 80px;
        padding: 0;

        @include media-breakpoint-up(md) {
            gap: 40px;
            grid-template-columns: 1fr 1fr;
        }
        @include media-breakpoint-up(lg) {
            gap: 80px;
            grid-template-columns: 1fr 1fr 1fr;
        }

        .card {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 24px;
            border-radius: 4px;
            border: none;

            .portrait {
                display: block;
                width: 100%;
                height: auto;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
            .card-body {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 4px;
                align-self: stretch;
                padding: 0;

                .fullname {
                    font-size: 22px;
                    line-height: 30px;
                    color: $navyBlue;
                    font-weight: 6700;
                    margin: 0;
                }
                .card-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 4px;
                    align-self: stretch;

                    .company-position {
                        font-family: $fontOpenSans;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 30px;
                        color: $navyBlue;
                        margin: 0;
                    }
                    .person-info {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #17253F;
                        display: none;
                    }
                    .personal-quote {
                        color: $independenceGray;
                        font-family: $fontOpenSans;
                        font-size: 16px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 30px; /* 187.5% */
                    }
                }
            }
        }
    }
    .logos {
        .owl-carousel {
            width: 100%;
            margin: 0 auto;

            @include media-breakpoint-up(lg) {
                width: 60%;
            }

            .owl-nav {
                position: absolute;
                width: 100%;
                top: 0px;
                bottom: 0px;
                margin: 0;
                display: flex;
                justify-content: space-between;
                align-content: center;
            }
            .owl-stage-outer {
                margin: 0;
            }
            .owl-stage {
                align-items: center;

                .owl-item {
                    .col {
                        display: flex;
                        justify-content: center;

                        img {
                            max-width: 160px;
                        }
                    }
                }
            }
        }
    }
    .contact-us {
        background-image: none;
        background-color: transparent;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 46px;

        .contact-us-grid {
            display: grid;
            grid-template-columns: 1fr;
            align-self: stretch;
            gap: 36px;
            padding: 36px;
            border-radius: 5px;
            background: $lightGray;

            @include media-breakpoint-up(md) {
                grid-template-columns: 1fr 1fr;
            }

            .text-block {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                align-self: stretch;
                flex: 1 0 0;
                margin: 0;

                .section-header {
                    h3 {
                        color: $navyBlue;
                    }
                }
                .contacts {
                    .contact {
                        &> p {
                            color: $independenceGray;
                            font-family: $fontOpenSans;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 38px; /* 190% */
                            margin: 0;
                        }
                        a {
                            color: $independenceGray;
                            text-decoration: none;

                            &:hover {
                                color: shade-color($independenceGray, 20%);
                            }
                        }
                    }
                }
            }
            .contact-form {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                gap: 14px;
                margin: 0;
                background-color: transparent;
            }
        }
        .extra-grid {
            display: grid;
            grid-template-columns: 1fr;
            align-items: flex-start;
            align-self: stretch;
            gap: 46px;

            @include media-breakpoint-up(md) {
                grid-template-columns: 1fr 1fr;
            }

            .help-block {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                flex: 1 0 0;
                gap: 12px;
                padding: 36px;
                background: $lightGray;

                .help-link {
                    text-decoration: none;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                    .help-link-title {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        align-self: stretch;
                        gap: 20px;

                        h4 {
                            color: $navyBlue;
                            font-size: 25px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 32px; /* 128% */
                            text-decoration: none;
                            margin: 0;
                        }
                    }
                    p {
                        color: $navyBlue;
                        font-family: $fontOpenSans;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 36px; /* 200% */
                        text-decoration: none;
                        margin: 0;
                    }
                }
            }
        }
    }
    .social-links {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 40px;
        margin-top: 24px;

        a {
            .icon {
                transition: all 0.2s ease-in-out;
            }
            &:hover {
                .icon {
                    fill: $navyBlue;
                }
            }
        }
    }
    .downloads {
        padding: 0px 40px 90px 40px;

        h2 {
            margin-bottom: 16px;
        }
        a {
            display: inline-block;
            color: $navyBlue;
            font-weight: 600;
            text-decoration: none;
            border-bottom: 2px solid $navyBlue;
        }
    }
}