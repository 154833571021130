.chart-collapse-btn {
    &> i {
        margin-right: 8px;
    }
    &::after {
        display: inline-block;
        content: "";
        vertical-align: .2em;
        margin-left: .1em;
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }
    &.collapsed::after {
        border-top: 0;
        border-right: .3em solid transparent;
        border-bottom: .3em solid;
        border-left: .3em solid transparent;
    }
}

.chart-collapse-cont {
    margin-top: 1rem;
}