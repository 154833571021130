.order-list-wrap {
    position: relative;
    min-height: 132px;

    .orders-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 48px;
        margin-top: 24px;

        .order {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 24px;
            width: 100%;

            .order-thumbnail {
                width: 88px;
                height: 88px;
                border-radius: 4px;
                flex-grow: 0;

                img {
                    width: 88px;
                    height: 88px;
                    border-radius: 4px;
                    object-fit: cover;
                }
            }
            .order-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-grow: 1;
                padding: 0px;
                gap: 4px;

                .order-id {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #444444;
                    margin: 0;
                }
                h5 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $navyBlue;
                    margin: 0;
                }
                .order-price {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 12px;
                    color: $navyBlue;
                    margin: 0;
                }
                .badges {
                    .badge {
                        padding: 0px 6px;
                        font-family: $fontMontserrat;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 18px;
                        border-radius: 15px;
                        margin-bottom: 0;
                    }
                }
            }
            .read-more {
                flex-grow: 0;
                font-family: $fontMontserrat;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: $navyBlue;
            }

            &:not(:last-child) {
                &::after {
                    content: "";
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: -24px;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: #EDEDED;
                }
            }
        }
    }
}

.order-details-view {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 36px;
    width: 100%;

    .order-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        flex-grow: 1;
    }
    .order-details-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 16px;
        width: 100%;

        .texts {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 4px;
            flex-grow: 1;
            width: 100%;

            h5 {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: $navyBlue;
                margin: 0;
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #444444;
                margin: 0;
            }
        }
        .badges {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            flex-grow: 0;

            .badge {
                padding: 0px 6px;
                font-family: $fontMontserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                border-radius: 15px;
                margin-bottom: 0;
            }
        }
    }
    .order-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        width: 100%;

        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: $navyBlue;
            margin: 0;
        }
        .order-items-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 12px;
            width: 100%;

            .order-item {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 24px;

                .texts {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 2px;
                    width: 100%;
                    flex-grow: 1;

                    h4, h4 > a {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: $navyBlue;
                        text-decoration: none;
                        margin: 0;
                    }
                    h4 > a:hover {
                        text-decoration: underline;
                    }
                    .price {
                        font-family: $fontMontserrat;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 30px;
                        color: $navyBlue;
                    }
                    .description {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        color: #444444;
                        margin: 0;
                    }
                }
                .order-thumbnail {
                    width: 64px;
                    height: 64px;
                    border-radius: 4px;

                    img {
                        width: 64px;
                        height: 64px;
                        border-radius: 4px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    .order-shipping-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;

        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: $navyBlue;
            margin: 0;
        }
        .shipping-row {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 4px;

            span {
                font-family: $fontMontserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #444444;
            }
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $navyBlue;
                margin: 0;
            }
        }
    }
    .payment-details {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 288px;
        padding: 16px;
        gap: 12px;        
        border: 1px solid #EDEDED;
        border-radius: 4px;

        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: $navyBlue;
            margin: 0;
        }
        .payment-method-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 16px;
            width: 100%;

            .texts {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                flex-grow: 1;

                span {
                    font-family: $fontMontserrat;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #444444;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $navyBlue;
                    margin: 0;
                }
            }
        }
        .payment-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 4px;
            width: 100%;

            span {
                font-family: $fontMontserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #444444;
                flex-grow: 1;
            }
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: right;
                color: $navyBlue;
                margin: 0;
            }
        }
    }
    hr {
        display: block;
        width: 100%;
        height: 2px;
        background: #EDEDED;
        margin: 0;
    }
}