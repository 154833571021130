.page-container {
    position: relative;
    max-width: $max-body-width;
    margin: 0 auto;
    padding: 1.5rem 1.75rem;

    @include media-breakpoint-up(lg) {
        padding: .75rem 2.25rem;
    }

    .toast-container {
        position: absolute;
        top: 0px;
        right: 0px;
    }
}

.page-title {
    position: relative;
    
    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        color: $navyBlue;
        margin: 8px 0 0 0;

        @include media-breakpoint-up(md) {
            font-size: 48px;
            line-height: 60px;
            margin: 18px 0 0 0;
        }
        @include media-breakpoint-up(lg) {
            margin: 48px 0 0 0;
        }
    }
    h2 {
        font-family: $fontWorkSans;
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 40px;
        letter-spacing: -0.02em;
        color: #444444;
        margin: 0;
    }
    h1 + h2 {
        margin: 24px 0 0 0;
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: $navyBlue;
        margin: 16px 0 0 0;

        @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 32px;
        }
    }
}

.page-box {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    box-shadow: none;

    @include media-breakpoint-up(lg) {
        margin: 48px 0 0 0;
    }

    .page-title {
        margin: 66px 0 0;

        h1 {
            text-align: center;
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 60px;
            color: $white;
            margin: 0;
        }
        &> p {
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: $white;
        }
    }
}

.page-box-light {
    .page-title {
        h1 {
            color: $navyBlue;
        }
        &> p {
            color: $navyBlue;
        }
    }
}

.page-title + .page-box {
    margin: 32px 0 0 0;
}

.box-icon {
    margin-bottom: 32px;

    &> i {
        font-size: 92px;
    }
}

.section-padding {
    padding: $vertical-section-spacing-sm !important;

    @include media-breakpoint-up(md) {
        padding: $vertical-section-spacing-md !important;
    }
    @include media-breakpoint-up(lg) {
        padding: $vertical-section-spacing-lg !important;
    }
}

.section-padding-y {
    padding-top: $vertical-section-spacing-sm !important;
    padding-bottom: $vertical-section-spacing-sm !important;

    @include media-breakpoint-up(md) {
        padding-top: $vertical-section-spacing-md !important;
        padding-bottom: $vertical-section-spacing-md !important;
    }
    @include media-breakpoint-up(lg) {
        padding-top: $vertical-section-spacing-lg !important;
        padding-bottom: $vertical-section-spacing-lg !important;
    }
}

.section-padding-top {
    padding-top: $vertical-section-spacing-sm !important;

    @include media-breakpoint-up(md) {
        padding-top: $vertical-section-spacing-md !important;
    }
    @include media-breakpoint-up(lg) {
        padding-top: $vertical-section-spacing-lg !important;
    }
}

.section-padding-top-lg {
    @include media-breakpoint-up(lg) {
        padding-top: $vertical-section-spacing-lg !important;
    }
}
.section-padding-top-xl {
    @include media-breakpoint-up(xl) {
        padding-top: $vertical-section-spacing-lg !important;
    }
}

.section-padding-bottom {
    padding-bottom: $vertical-section-spacing-sm !important;

    @include media-breakpoint-up(md) {
        padding-bottom: $vertical-section-spacing-md !important;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: $vertical-section-spacing-lg !important;
    }
}

.section-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;

    h5 {
        color: $independenceGray;
        font-family: $fontOpenSans;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px; /* 128.571% */
        margin: 0;
    }
    h3 {
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px; /* 126.316% */
        margin: 0;
    }
    &> p {
        color: $independenceGray;
        font-family: $fontOpenSans;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 166.667% */
        margin: 0;
    }
}