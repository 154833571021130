$select-arrow-icon:  url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5 9L11.2191 14.3306C11.6684 14.7158 12.3316 14.7158 12.7809 14.3306L19 9' stroke='#17253F' stroke-width='1.5' stroke-linecap='round'/></svg>");

.form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 0px;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
    label {
        position: relative;
        width: 100%;
        font-family: $fontMontserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #444444;
        background: $white;
        border: 1px solid #F2F2F2;
        border-radius: 4px;
        padding: 12px 14px 42px 14px;
    }
    .form-control {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        border: none;
        background-color: transparent;
        font-family: $fontMontserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $navyBlue;
        padding: 42px 14px 10px 14px;

        &::placeholder {
            color: tint-color($navyBlue, 60%);
        }
    }
    .input-group {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;

        .form-control {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            flex-grow: 1;
        }
    }
    .form-control-wrap {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        background: $white;
        border: 1px solid #F2F2F2;
        border-radius: 4px;

        label {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            padding: 12px 14px;
            background-color: transparent;
            border: none;
        }
        .form-control {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            padding: 42px 14px 10px 14px;
        }
    }
    .invalid-feedback {
        display: none;
    }
    &.is-invalid {
        label {
            border: 1px solid $danger;
        }
        .form-control-wrap {
            border: 1px solid $danger;

            label {
                border: none;
            }
        }
        .invalid-feedback {
            display: block;
            padding: 0 6px;
            margin-bottom: 0;
        }
    }
}

.field-info {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #727272;
    margin: 0;
}

.form-group + .field-info {
    display: block;
    max-width: 70%;
    padding: 0 6px;
    margin-top: -16px;
    margin-bottom: 24px;
}

.modal {
    .form-group + .field-info {
        max-width: 100%;
    }
}

.inline-form-groups {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0px;
    gap: 16px;

    &:last-child {
        margin-bottom: 0;
    }
    
    .form-group {
        flex-grow: 1;

        &:last-child {
            margin-bottom: 24px;
        }
    }
}

.form-group-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    gap: 4px;
    background: #FFFFFF;
    border: 1px dashed #F2F2F2;
    border-radius: 4px;

    label {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        text-align: center;
    }
    .form-control {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        opacity: 0;
    }
    .selected-file-name {
        display: none;
        font-family: $fontMontserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #444444;
        overflow-wrap: anywhere;
    }
    &.file-selected {
        label {
            display: none;
        }
        .selected-file-name {
            display: block;
        } 
    }
}

.select-field-group {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 30px;
        right: 18px;
        display: block;
        width: 24px;
        height: 24px;
        background-image: escape-svg($select-arrow-icon);
        pointer-events: none;
    }
    .form-control {
        padding: 42px 62px 12px 12px;
    }
}

.password-visibility {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    display: block;
    width: 100%;

    &> .form-control {
        position: relative;
        padding-right: 54px;
    }
    .btn-toggle {
        position: absolute;
        top: 20px;
        right: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
    }
}

.form-links {
    margin-bottom: 24px;
    
    a {
        font-family: $fontMontserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $midnightBlue;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

form {
    button[type="submit"]:not(.btn-sm),
    input[type="submit"]:not(.btn-sm) {
        display: block;
        width: 100%;
        padding: 14px 24px;
    }
    button[type="submit"].btn-sm,
    input[type="submit"].btn-sm {
        font-size: 16px;
        line-height: 24px;
        padding: 8px 24px;
    }
}

.page-form {
    .form-group:not(.is-invalid) {
        label {
            border: 1px solid #999999;
        }
    }
}

.enable-by-fill:not(.filled) {
    button[type="submit"],
    input[type="submit"] {
        background-color: #D8D8D8;
        border-color: #D8D8D8;
    }
}

.form-description {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #444444;
    margin: 44px 0 28px 0;
}