.blog-wrapper {
    .badge {
        color: #FFF;
        font-family: $fontMontserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        text-transform: uppercase;
        margin-right: 0;
        border: 2px solid;
        border-radius: 40px;
        padding: 0px 28px;

        &.badge-primary {
            background-color: $navyBlue;
            border-color: $navyBlue;
        }
        &.badge-outlined-primary {
            background-color: #FFF;
            border-color: $navyBlue;
            color: $navyBlue;
        }
        &.badge-denim {
            background-color: $midnightBlue;
            border-color: $midnightBlue;
        }
        &.badge-outlined-denim {
            background-color: #FFF;
            border-color: $midnightBlue;
            color: $midnightBlue;
        }
        &.badge-mint {
            background-color: $mint;
            border-color: $mint;
        }
        &.badge-outlined-mint {
            background-color: #FFF;
            border-color: $mint;
            color: $mint;
        }
        &.badge-emerald {
            background-color: $emeraldGreen;
            border-color: $emeraldGreen;
        }
        &.badge-outlined-emerald {
            background-color: #FFF;
            border-color: $emeraldGreen;
            color: $emeraldGreen;
        }
        &.badge-mustard {
            background-color: $mustard;
            border-color: $mustard;
        }
        &.badge-outlined-mustard {
            background-color: #FFF;
            border-color: $mustard;
            color: $mustard;
        }
        &.badge-gold {
            background-color: $gold;
            border-color: $gold;
        }
        &.badge-outlined-gold {
            background-color: #FFF;
            border-color: $gold;
            color: $gold;
        }
        &.badge-danger {
            background-color: $danger;
            border-color: $danger;
        }
        &.badge-outlined-danger {
            background-color: #FFF;
            border-color: $danger;
            color: $danger;
        }
        &.badge-success {
            background-color: $success;
            border-color: $success;
        }
        &.badge-outlined-success {
            background-color: #FFF;
            border-color: $success;
            color: $success;
        }
    }
}

.blog-wrapper,
.blog-container {
    position: relative;
    max-width: $max-body-width;
    margin: 0 auto;
    padding: 0 28px;

    @include media-breakpoint-up(lg) {
        padding: 0 60px;
    }
}

.blog-title {
    padding-top: 36px;
    padding-bottom: 32px;
    background: $lightGray;

    .blog-container {
        padding-top: 0;
        padding-bottom: 0;
    }
    h1 {
        font-size: 36px;
        line-height: 44px;

        @include media-breakpoint-up(lg) {
            line-height: 70px;
        }
    }
}

.blog-carousel-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 50px 0 0 0;

    @include media-breakpoint-up(md) {
        padding: 66px 0 0 0;
    }
    @include media-breakpoint-up(lg) {
        padding: 96px 0 0 0;
    }

    h2 {
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        color: #444;
        margin: 0;
    }
    .carousel {
        width: 100vw;
        margin: -50px -28px 0 -28px;
        
        @include media-breakpoint-up(lg) {
            width: calc(100% + 100px);
            margin: -50px -50px 0 -50px;
        }

        .carousel-inner {
            .carousel-item {
                padding: 50px 28px;

                @include media-breakpoint-up(lg) {
                    padding: 50px;
                }
            }
            .carousel-article {
                display: flex;
                flex: auto 0;
                flex-direction: row;
                justify-content: flex-start;
                height: auto;
                border-radius: 16px;
                background: #FFF;
                box-shadow: 0px 15px 34px 0px rgba(23, 37, 63, 0.16);

                .carousel-article-left {
                    display: flex;
                    flex-grow: 1;
                    width: auto;
                    padding: 29px 40px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;

                    @include media-breakpoint-up(md) {
                        width: 50%;
                        gap: 30px;
                    }
                    @include media-breakpoint-up(lg) {
                        gap: 38px;
                    }

                    .article-top-info {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-self: stretch;
                        flex-wrap: wrap;

                        .article-date {
                            flex-shrink: 0;
                            color: #444;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 26px;
                            margin: 0;
                            flex-grow: 1;

                            @include media-breakpoint-up(md) {
                                line-height: 35px;
                            }
                        }
                        .article-tags {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            gap: 10px;

                            @include media-breakpoint-up(md) {
                                gap: 16px;
                            }

                            .badge {
                                font-size: 10px;
                                line-height: 21px;
                                margin-right: 0;
                                padding: 0px 8px;

                                @include media-breakpoint-up(md) {
                                    font-size: 12px;
                                    line-height: 26px;
                                    padding: 0px 14px;
                                }
                            }
                        }
                    }
                    .article-title {
                        color: $articleTitleLinkColor;
                        font-size: 24px;
                        line-height: 30px;
                        font-style: normal;
                        font-weight: 700;
                        transition: color .1s ease-in-out;
                        margin: 6px 0 0 0;

                        @include media-breakpoint-up(md) {
                            font-size: 32px;
                            line-height: 40px;
                            margin: 0;
                        }
                    }
                    .article-link {
                        text-decoration: none;

                        &:hover {
                            .article-title {
                                color: tint-color($articleTitleLinkColor, 25%);
                            }
                        }
                    }
                    .article-text {
                        color: #434343;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        margin: 0;

                        @include media-breakpoint-up(md) {
                            font-size: 18px;
                        line-height: 30px;
                        }
                    }
                    .article-readmore {
                        color: $midnightBlue;
                        font-family: $fontMontserrat;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 18px;
                        letter-spacing: -0.28px;
                        transition: color .1s ease-in-out;

                        &:hover {
                            color: shade-color($midnightBlue, 25%);
                        }
                    }
                }
                .carousel-article-right {
                    display: none;

                    @include media-breakpoint-up(md) {
                        display: block;
                        width: 50%;
                    }

                    .article-thumb {
                        display: block;
                        width: auto;
                        height: 100%;
                        border-top-right-radius: 16px;
                        border-bottom-right-radius: 16px;
                        overflow: hidden;

                        @include media-breakpoint-up(lg) {
                            width: auto;
                        }

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            border: none;
                            object-fit: cover;
                            aspect-ratio: 16/9;
                            transition: transform .2s;
                        }
                        &:hover {
                            img {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
        .carousel-control-next, 
        .carousel-control-prev {
            display: none;
            width: 50px;
            margin: 50px 0;

            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }
        .carousel-control-next {
            right: -16px;
        }
        .carousel-control-prev {
            left: -16px;
        }
        .carousel-indicators {
            margin: 0;
            bottom: -6px;

            button {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #D9D9D9;

                &.active {
                    background-color: #7A7A7A;
                }
            }
        }
    }
}

.blog-browse-wrap {
    position: relative;
}

.blog-browse {
    margin: 76px 0 0 0;

    .blog-browse-filters {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;
        flex-wrap: wrap;

        .search-group {
            position: relative;
            margin-bottom: 0px;
            flex-grow: 1;

            .search-input {
                color: #000;
                font-family: $fontMontserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 36px;
                border-radius: 55px;
                border: 2px solid #EBEBEB;
                padding-left: 50px;
                padding-right: 16px;
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 380px;
                }
            }
            &> i {
                position: absolute;
                top: 50%;
                margin-top: -7px;
                left: 20px;
                pointer-events: none;
            }
        }
        .filter-tags {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 12px;
            flex-wrap: wrap;

            @include media-breakpoint-up(md) {
                gap: 20px;
            }

            .tag-filter {
                font-family: $fontMontserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                text-transform: uppercase;
                margin-right: 0;
                border-radius: 40px;
                padding: 0px 18px;

                @include media-breakpoint-up(md) {
                    font-size: 14px;
                    line-height: 38px;
                    padding: 0px 28px;
                }
            }
        }
    }
    .blog-browse-results {
        .no-results {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 32px 0;
    
            h4 {
                font-size: 1rem;
            }
            img {
                width: 100%;
                max-width: 300px;
            }
        }
    }
    .blog-pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 12px;

        .loadmore-btn {
            min-width: 200px;
            padding: $btn-padding-y-sm 1.5rem;
        }
    }
}

.blog-breadcrumbs {
    display: block;
    text-align: left;
    color: #444;
    font-family: $fontMontserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    margin-top: 42px;
}

.article-readtime {
    color: #444;
    font-family: $fontMontserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    text-align: left;
    margin-top: 16px;
}

.blog-article {
    header {
        border-bottom: none;
    }
    .article-top-image {
        margin-top: 16px;
        margin-bottom: 34px;
        border-radius: 15px;

        img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 16/9;
            border-radius: 15px;
        }
    }
    .article-date {
        color: #444;
        font-family: $fontMontserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0.42px;
        text-align: left;
    }
    .article-cats {
        display: flex;
        align-items: center;
        align-self: stretch;
        gap: 12px;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            gap: 50px;
        }

        .article-tags {
            display: flex;
            align-items: center;
            gap: 18px;

            .badge {
                font-size: 12px;
                line-height: 20px;
                margin-right: 0;
                margin-bottom: 0;
                padding: 0px 10px;
            }
        }
    }
    .article-title {
        color: #17253F;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin-top: 44px;
        max-width: 700px;
        margin-right: auto;

        @include media-breakpoint-up(md) {
            margin-top: 26px;
        }
    }
    .article-short-info {
        margin-top: 20px;
        max-width: 700px;
        margin-right: auto;

        &> p {
            color: #434343;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 0;
        }
        a {
            text-decoration: none;
            color: $brilliantAzure;
            transition: color 0.1s ease-in-out;
            
            &:hover {
                color: shade-color($brilliantAzure, 25%);
            }
        }
    }
    .article-content {
        margin-top: 56px;
        padding-bottom: 36px;
        border-bottom: 2px solid #E6E6E6;
    }
}
.shop-browse-article,
.inpage-article {
    .blog-article {
        .article-content {
            padding-bottom: 56px;
            border-bottom: none;
        }
    }
}
.shop-article {
    padding: 86px 0;
}
.article-content {
    font-family: $fontMontserrat;
    max-width: 740px;
    margin-right: auto;
    word-wrap: break-word;

    h1, h2, h3, h4, h5, h6 {
        color: #17253F;
        font-family: $fontMontserrat;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 12px;
    }
    h2 {
        font-size: 32px;
        line-height: 40px;
    }
    p, li {
        color: #444;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px;
    }
    a {
        text-decoration: none;
        color: $brilliantAzure;
        transition: color 0.1s ease-in-out;
        
        &:hover {
            color: shade-color($brilliantAzure, 25%);
        }
    }
    img {
        max-width: 100%;
    }
    video {
        max-width: 100%;
    }
    .article-content-main-takeaways {
        margin-bottom: 56px;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    .article-chart-wrap {
        padding: 0 2px;
    }
}
.article-widgets {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
        padding-top: 16px;
    }

    .article-widgets-wrap {
        position: sticky;
        top: 36px;
        z-index: 1020;
    }
    .article-legend {
        padding-left: 14px;
        border-left: 2px solid #E6E6E6;
    
        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            padding-left: 0px;
    
            li {
                position: relative;
                list-style: none;
    
                a {
                    color: #444;
                    text-align: center;
                    font-family: $fontMontserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 30px;
                    text-decoration: none;
    
                    &.active {
                        font-weight: 600;
    
                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 0px;
                            left: -16px;
                            bottom: 0px;
                            border-left: 2px solid #747474;
                        }
                    }
                }
            }
        }
    }
    .article-main-takeaways {
        display: flex;
        width: 100%;
        padding: 34px 18px 34px 38px;
        flex-direction: column;
        align-items: flex-start;
        gap: 22px;
        border-radius: 16px;
        background: #F4F5F7;
        margin-top: 38px;

        h3 {
            color: #444;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 34px;
            margin: 0;
        }
        .mta-body {
            color: #444;
            font-family: $fontMontserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;

            &> *:last-child {
                margin-bottom: 0;
            }
            ul, ol {
                margin-left: -28px;

                li {
                    margin-bottom: 22px;
                }
                li:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.related-articles {
    margin-top: 112px;
    margin-bottom: 100px;

    h2 {
        color: #17253F;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 70px;
        margin-bottom: 20px;
    }
    .article-cards {
        margin-top: 0px;
    }
}