.homepage-intro {
    display: flex;
    flex-direction: column-reverse;
    justify-content: stretch;

    @include media-breakpoint-up(lg) {
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        grid-template-columns: 400px 1fr;
    }
    @include media-breakpoint-up(xl) {
        grid-template-columns: 480px 1fr;
    }

    .text-block {
        padding: 76px 1.75rem;

        @include media-breakpoint-up(lg) {
            padding: 76px 2.25rem;
        }

        .texts-wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 8px;
            align-self: stretch;
            width: auto;

            @include media-breakpoint-up(lg) {
                width: 340px;
            }
            @include media-breakpoint-up(xl) {
                width: 380px;
            }

            .metametals {
                color: $independenceGray;
                font-family: $fontOpenSans;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 34px; /* 170% */
                opacity: 0.7;
            }
            h1 {
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 38px;
                margin: 0;
            }
            .buttons {
                margin-top: 50px;

                .btn {
                    padding: 1rem;
                    margin-right: 16px;

                    @include media-breakpoint-up(md) {
                        padding: 1rem 1.5rem;
                    }
                }
                .btn:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
    .image-block {
        &> img {
            margin-bottom: -72px;

            @include media-breakpoint-up(md) {
                margin-bottom: -100px;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: -152px;
            }
        }
    }
}

.homepage-bg-dark {
    background: #FAFAFA;
}

section[class^="homepage"] {
    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        color: $navyBlue;

        @include media-breakpoint-up(md) {
            font-size: 48px;
            line-height: 60px;
        }
    }
    h2 + p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #434343;
    }
}

.homepage-payment-providers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    @include media-breakpoint-up(lg) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .payment-providers {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 0px;
        padding-left: 0;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            gap: 4px;
        }

        li {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 30px;
        
            &> svg {
                max-width: 40px;
            }
            &:first-child svg {
                max-width: 55px;
            }
        }
    }
    p {
        color: $independenceGray;
        text-align: center;
        font-family: $fontOpenSans;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 180% */
    }
}

.homepage-banners {
    &> a {
        color: $independenceGray;
        font-family: $fontOpenSans;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px; /* 128.571% */
        text-decoration: none;
        display: inline-block;
        margin-top: 48px;

        &:hover {
            color: shade-color($independenceGray, 25%);
        }
    }
    .banners-grid {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 26px;

        @include media-breakpoint-up(xl) {
            display: grid;
            grid-auto-rows: 1fr;
            grid-template-columns: 1fr 1fr;
            align-items: flex-start;
            gap: 66px;
        }

        .banner {
            display: flex;
            height: auto;
            justify-content: flex-start;
            align-items: center;
            gap: 22px;
            padding-right: 30px;
            background: $lightGray;
            text-decoration: none;
            padding: 20px 30px 20px 0;

            @include media-breakpoint-up(md) {
                align-self: stretch;
                gap: 40px;
                height: 180px;
                padding: 0 30px 0 0;
            }

            .banner-inner {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 1 0 0;

                .banner-image-container {
                    display: none;
                    width: 180px;
                    height: 180px;
                    object-fit: cover;
                    overflow: hidden;

                    @include media-breakpoint-up(md) {
                        display: block;
                    }

                    img {
                        transition: transform .2s ease-in-out;
                    }
                }
                .banner-texts {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 10px;
                    flex: 1 0 0;
                    padding-left: 26px;

                    .banner-title {
                        color: $navyBlue;
                        font-family: $fontMontserrat;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 38px;

                        @include media-breakpoint-up(md) {
                            font-size: 38px;
                            line-height: 48px;
                        }
                    }
                    p {
                        color: $independenceGray;
                        font-family: $fontOpenSans;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 22px;
                        margin: 0;
                        opacity: 0.7;
                        transition: opacity .2s ease-in-out;

                        @include media-breakpoint-up(md) {
                            font-size: 21px;
                            line-height: 27px;
                        }
                    }
                }
            }
            &:hover {
                .banner-inner {
                    .banner-image-container {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .banner-texts {
                        p {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.homepage-product-banners {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;

    .banners-grid {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        row-gap: 40px;
        padding: 0;

        @include media-breakpoint-up(md) {
            gap: 40px;
            grid-template-columns: 1fr 1fr;
        }
        @include media-breakpoint-up(lg) {
            gap: 40px;
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include media-breakpoint-up(xl) {
            gap: 80px;
        }

        .banner {
            display: flex;
            width: auto;
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            flex-shrink: 0;
            background: #FCFCFC;
            box-shadow: 0px 12px 28px 0px rgba(23, 37, 63, 0.13);

            .image-block {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                align-self: stretch;
                padding: 40px 0px;

                &> img {
                    width: 50%;
                    align-self: center;
                }
            }
            .content {
                display: flex;
                padding: 30px 40px;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                gap: 16px;

                .text-block {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    align-self: stretch;
                    gap: 12px;

                    .banner-title {
                        color: $navyBlue;
                        font-family: $fontMontserrat;
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 32px; /* 128% */
                    }
                    .attributes-block {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        align-self: stretch;
                        gap: 8px;
                        list-style: none;
                        padding-left: 20px;

                        &> li {
                            position: relative;
                            list-style: none;

                            &> i {
                                font-size: 14px;
                                color: #15D49B;
                                position: absolute;
                                top: 6px;
                                left: -21px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.homepage-new-to-investing .content,
.homepage-carousel .carousel .carousel-inner .carousel-item .carousel-item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    background: $lightGray;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }

    .image-block {
        object-fit: cover;
    }
    .content-block {
        display: flex;
        padding: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: center;
        gap: 44px;
        flex: 1 0 0;

        @include media-breakpoint-up(md) {
            padding: 64px;
        }
        @include media-breakpoint-up(xl) {
            padding: 64px 134px 64px 80px;
        }

        .text-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            gap: 0px;

            h5 {
                color: $independenceGray;
                font-family: $fontOpenSans;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                margin: 0;

                @include media-breakpoint-up(md) {
                    font-size: 21px;
                    line-height: 27px;
                }
            }
            h4 {
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 36px;
                margin: 6px 0 0 0;

                @include media-breakpoint-up(md) {
                    font-size: 38px;
                    line-height: 48px;
                }
            }
            p {
                color: $independenceGray;
                font-family: $fontOpenSans;
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: 27px;
                margin: 16px 0 0 0;

                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
    }
}

.homepage-new-to-investing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    .content {
        .image-block {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 550px;
            }
        }
    }
    .links {
        display: none;
        grid-template-columns: 1fr;
        gap: 36px;
        padding: 10px 0px;

        @include media-breakpoint-up(md) {
            display: grid;
            gap: 24px 60px;
            grid-template-columns: 1fr 1fr;
        }
        @include media-breakpoint-up(lg) {
            gap: 24px 60px;
            grid-template-columns: 1fr 1fr;
        }
        @include media-breakpoint-up(xl) {
            gap: 26px 128px;
            grid-template-columns: 1fr 1fr 1fr;
        }

        .link {
            width: auto;
            flex-shrink: 0;
            color: $independenceGray;
            font-family: $fontOpenSans;
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 130.435% */
            text-decoration-line: underline;
        }
    }
}

.homepage-carousel {
    .carousel {
        .carousel-inner {
            .carousel-item {
                .carousel-item-content {
                    .image-block {
                        width: 100%;
            
                        @include media-breakpoint-up(lg) {
                            width: 650px;
                        }
                    }
                }
            }
        }
        .carousel-controls {
            position: relative;
            left: auto;
            right: auto;
            bottom: auto;
            z-index: auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 24px;
            margin: 26px 0 0 0;

            .carousel-indicators {
                position: relative;
                left: auto;
                right: auto;
                bottom: auto;
                z-index: auto;
                margin: 0;

                &> button {
                    display: block;
                    border: none;
                    width: 10vw;
                    max-width: 200px;
                    height: 3px;
                    background: rgba(23, 37, 63, 0.10);

                    @include media-breakpoint-up(md) {
                        width: 15vw;
                    }
                    &.active {
                        background: $blueNcs;
                    }
                }
            }
            .carousel-control-prev,
            .carousel-control-next {
                position: relative;
                width: auto;

                svg {
                    opacity: 0.5;

                    .icon {
                        stroke: $navyBlue;
                    }
                }
                &:hover {
                    svg {
                        opacity: 1;

                        .icon {
                            stroke: $blueNcs;
                        }
                    }
                }
            }
        }
    }
}

.homepage-models {
    margin: 0;

    .homepage-models-carousel-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        gap: 24px;
        padding: 0;
        
        .link {
            font-family: $fontOpenSans;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 100% */
            letter-spacing: -0.36px;
            color: $blueNcs;
            text-decoration: underline;

            &:hover {
                color: shade-color($blueNcs, 25%);
            }
        }
    }
    .carousel {
        width: 100%;
        
        .carousel-item {
            text-align: center;

            h5 {
                color: $independenceGray;
                text-align: center;
                font-family: $fontOpenSans;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                margin-top: 36px;
                margin-bottom: 20px;
            }
        }
        .carousel-control-prev,
        .carousel-control-next {
            width: 20%;

            @include media-breakpoint-up(lg) {
                width: 15%;
            }
            @include media-breakpoint-up(xl) {
                width: 10%;
            }

            .carousel-control {
                width: 100%;
                height: 100%;
                padding: 0;
                border: none;
                background-color: transparent;
                opacity: 1;
    
                svg {
                    opacity: 0.5;
    
                    .icon {
                        stroke: $navyBlue;
                    }
                }
                &:hover {
                    svg {
                        opacity: 1;
    
                        .icon {
                            stroke: $blueNcs;
                        }
                    }
                }
            }
        }
        .carousel-indicators {
            position: relative;
            left: auto;
            right: auto;
            bottom: auto;
            z-index: auto;
            margin: 0;

            &> button {
                display: block;
                border: none;
                width: 5vw;
                max-width: 200px;
                height: 3px;
                background: rgba(23, 37, 63, 0.10);

                @include media-breakpoint-up(md) {
                    width: 15vw;
                }
                &.active {
                    background: $blueNcs;
                }
            }
        }
    }
    .threed-model {
        position: relative;
        width: 100%;
        height: 600px;
        margin: 0 auto;

        &.manual-load-3d {
            display: block;
        }

        .lazy-load-poster {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            overflow: hidden;

            &> img {
                width: 240%;

                @include media-breakpoint-up(md) {
                    width: 184%;
                }
                @include media-breakpoint-up(lg) {
                    width: 138%;
                }
                @include media-breakpoint-up(xl) {
                    width: 100%;
                }
            }
        }
    }
}

.homepage-why-metametals {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    @include media-breakpoint-up(lg) {
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr 2fr;
        align-items: flex-start;
        gap: 0;
    }

    .section-header {
        padding-right: $vertical-section-spacing-sm;
        gap: 16px;

        @include media-breakpoint-up(md) {
            padding-right: $vertical-section-spacing-md !important;
        }
        @include media-breakpoint-up(lg) {
            padding-right: $vertical-section-spacing-lg !important;
        }
    }
    .content {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 64px;

        @include media-breakpoint-up(lg) {
            display: grid;
            grid-auto-rows: 1fr;
            grid-template-columns: 1fr 1fr;
            align-items: flex-start;
            gap: 80px;
            row-gap: 64px;
        }

        .quality-box {
            display: flex;
            width: auto;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            gap: 10px;

            &> img {
                display: block;
                width: 220px;
                margin: 0 auto 20px auto;

                @include media-breakpoint-up(md) {
                    margin: 0;
                }
            }
            h4 {
                font-size: 25px;
                font-style: normal;
                font-weight: 600;
                line-height: 33px; /* 132% */

                @include media-breakpoint-up(md) {
                    max-width: 312px;
                }
            }
            p {
                color: $navyBlue;
                font-family: $fontOpenSans;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 36px; /* 200% */
                margin: 0;
        
                @include media-breakpoint-up(md) {
                    padding-right: $vertical-section-spacing-md !important;
                }
                @include media-breakpoint-up(lg) {
                    padding-right: 0 !important;
                }
            }
        }
    }
}

.homepage-company {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 54px;

    .section-header {
        max-width: 640px;
        gap: 16px;

        .btn {
            margin-top: 8px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }
    .links-grid {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        @include media-breakpoint-up(lg) {
            display: grid;
            grid-auto-rows: 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: flex-start;
            gap: 24px;
        }

        .link {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            gap: 12px;
            padding: 36px;
            background: $lightGray;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
            .link-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                align-self: stretch;
                gap: 20px;

                h4 {
                    color: $navyBlue;
                    font-family: $fontMontserrat;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 32px; /* 128% */
                    margin: 0;
                }
            }
            p {
                color: $navyBlue;
                font-family: $fontOpenSans;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 36px; /* 200% */
                margin: 0;
            }
        }
    }
}