.dropdown-menu {
    padding: 8px;

    .dropdown-item {
        padding: 4px 8px;
        border-radius: 4px;
        font-family: $fontMontserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $navyBlue;

        &:hover {
            background-color: #F4F6FB;
            color: $navyBlue;
        }
        &:active {
            background-color: #dcdee4;
            color: $navyBlue;
        }
    }
}

.dropdown-toggle {
    &.no-arrow {
        &::after {
            display: none;
        }
    }
}

.language-dropdown-menu {
    min-width: 12rem;

    .dropdown-item {
        position: relative;

        svg {
            margin-right: 6px;
            vertical-align: text-top;
        }
        &.active {
            &:not(:hover) {
                background-color: transparent;
            }
            &::after {
                content: escape-svg($nav-language-active-icon);
                display: block;
                width: 16px;
                height: 16px;
                position: absolute;
                top: 6px;
                right: 8px;
            }
        }
    }
}

.wallet-dropdown-menu {
    min-width: 14rem;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    padding: 14px;

    &.show {
        display: flex;
    }

    .dropdown-item {
        position: relative;
        line-height: 25px;

        img {
            margin-right: 8px;
            vertical-align: top;
        }
    }
}