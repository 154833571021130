.product-cards {
    position: relative;
    margin-top: 56px;
    margin-bottom: 44px;
    
    &.owl-carousel {
        position: relative;

        .owl-stage-outer {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .owl-item {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
        }
        .owl-nav {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            display: block;
            padding: 0;
            pointer-events: none;

            &.disabled {
                display: none;
            }

            [class*=owl-] {
                position: absolute;
                top: 50%;
                margin-top: -80px;
                width: 40px;
                height: 40px;
                padding: 0;
                border: 2px solid #e8e8e8;
                background-color: transparent;
                border-radius: 35px;
                opacity: .6;
                transition: opacity .2s ease-in-out;
                pointer-events: all;
                color: $navyBlue;
                line-height: 14px;

                &:hover {
                    background: transparent;
                    color: $navyBlue;
                    opacity: 1;
                }
                &> i {
                    font-size: 22px;
                }
            }

            .owl-prev {
                left: -60px;
            }
            .owl-next {
                right: -60px;
            }
        }
    }

    .card {
        border: none;
        padding: 0 6px;

        .card-img-top {
            position: relative;
            border-radius: 0;
            margin-bottom: 16px;
            overflow: hidden;

            &> img {
                width: 100%;
                object-fit: cover;
                aspect-ratio: 41/34;
                object-position: top;
                transition: transform .2s;
            }
        }
        .card-body {
            padding: 0px;
            
            .product-name {
                color: $navyBlue;
                font-family: $fontOpenSans;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 25px; /* 138.889% */
            }
            .muted {
                color: #444F64;
            }
            .card-text {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: $midnightBlue;
                margin: 0;
            }
            .product-price {
                color: $navyBlue;
                font-family: $fontOpenSans;
                font-size: 26px;
                font-style: normal;
                font-weight: 600;
                line-height: 27px; /* 103.846% */
                margin-top: 12px;    
            }
        }
        .product-link {
            color: $navyBlue;
            text-decoration: none;

            &:hover {
                &> .product-name {
                    color: $midnightBlue;
                }
                & .card-img-top > img {
                    transform: scale(1.1);
                }
            }
        }
        .btn {
            display: block;
            padding: 14px 24px;
            margin-top: 34px;
            border: 1px solid $navyBlue;
            border-radius: 3px;
        }
    }
    .product-out-of-stock {
        opacity: 0.8;
    }
}

.article-cards {
    position: relative;
    margin-top: 70px;
    
    & > .col,
    & > [class*="col-"] {
        display: flex;
        align-items: stretch;
        padding-right: 18px;
        padding-left: 18px;
    }

    .card {
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 11px 26px 0px rgba(23, 37, 63, 0.24);
        border: none;
        padding: 0;
        margin-bottom: 38px;

        .article-thumb {
            position: relative;
            box-shadow: none;
            border-radius: 15px;
            margin-bottom: 0;
            background-color: whitesmoke;
            overflow: hidden;

            &> img {
                --initial-width: 768px;
                width: 100%;
                object-fit: cover;
                aspect-ratio: 16/9;
                object-position: center;
                transition: transform .2s;

                @include media-breakpoint-up(xl) {
                    --initial-width: 768px;
                }
            }
            &:hover {
                &> img {
                    transform: scale(1.1);
                }
            }
        }
        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 6px;
            padding: 20px;
            
            .article-top-info {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-self: stretch;
                flex-wrap: wrap;
                
                .article-date {
                    flex-shrink: 0;
                    color: #444;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px;
                    margin: 0;
                    flex-grow: 1;
                }
                .article-tags {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: 12px;

                    .badge {
                        font-size: 12px;
                        line-height: 22px;
                        margin-right: 0;
                        padding: 0px 14px;
                    }
                }
            }
            .article-title {
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 30px;
                text-align: left;
                color: $articleTitleLinkColor;
            }
            .article-link {
                text-decoration: none;

                &:hover {
                    .article-title {
                        color: tint-color($articleTitleLinkColor, 25%);
                    }
                }
            }
            .article-text {
                color: #434343;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                margin: 0;
            }
            .article-readmore {
                font-family: $fontMontserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                text-decoration: underline;
                color: $midnightBlue;

                &:hover {
                    color: shade-color($midnightBlue, 25%);
                }
            }
        }
    }
}

.faq-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 26px;

    @include media-breakpoint-up(md) {
        gap: 44px;
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }

    .still-need-help {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 24px;
        margin-top: 26px;
        
        p {
            color: $independenceGray;
            font-family: $fontOpenSans;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 25px; /* 138.889% */
            margin: 0;
        }
        .buttons-group {
            display: flex;
            align-items: flex-start;
            gap: 24px;

            .btn {
                font-size: 14px;
                min-width: 120px;
                padding: 6px 28px;
            }
        }
    }
    .text-block {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex-shrink: 0;
        align-self: stretch;
        padding-right: 20px;

        @include media-breakpoint-up(lg) {
            width: 25%;
        }
        @include media-breakpoint-up(xl) {
            width: 384px;
        }

        .still-need-help {
            display: none;

            @include media-breakpoint-up(md) {
                display: flex;
            }
        }
    }
    .section-title {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        gap: 16px;

        h3 {
            font-size: 38px;
            font-style: normal;
            font-weight: 600;
            line-height: 48px; /* 126.316% */
            margin: 0;
        }
        p {
            color: $independenceGray;
            font-family: $fontOpenSans;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 166.667% */
            margin: 0;
        }
    }
    .accordion-block {
        flex: 1 0 0;
        
        .accordion {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
            width: 100%;

            .accordion-item {
                width: 100%;
                border: none;
                border-radius: 0;
                background-color: transparent;
                border-bottom: 1px solid rgba(23, 37, 63, 0.20);;
                padding: 24px 0;

                .accordion-header {
                    .accordion-button {
                        color: $navyBlue;
                        font-family: $fontOpenSans;
                        font-size: 21px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 27px; /* 128.571% */
                        hyphens: auto;
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                        box-shadow: none;
                        background-color: transparent;
                        border-radius: 0;
                        padding: 0px;
                        gap: 16px;
                    }
                }
                .accordion-body {
                    font-family: $fontMontserrat;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                    color: #444444;
                    padding: 16px 0 0 0;
                }
            }
        }
        .still-need-help {
            display: flex;
            margin-top: 36px;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }
}