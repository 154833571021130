.checkout-start {

}

.checkout-shipping {
    .shipping-rate-options {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 28px;

        .shipping-rate-option {
            list-style: none;
            width: 100%;

            .form-check {
                position: relative;
                padding: 0px;

                .form-check-input {
                    position: absolute;
                    clip: rect(0,0,0,0);
                    pointer-events: none;
                }
                .form-check-label {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 24px 24px 24px 68px;
                    gap: 0px;
                    min-height: 94px;
                    background: #FFFFFF;
                    border: 1px solid #999999;
                    border-radius: 4px;
                    box-sizing: border-box;

                    .rate-description {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 0px;

                        h5 {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                            color: #444444;
                            margin: 0;
                        }
                        h3 {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 36px;
                            line-height: 44px;
                            color: $navyBlue;
                            margin: 0;
                        }
                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #444444;
                            margin: 4px 0 0 0;
                        }
                    }
                    .rate-price {
                        font-weight: 600;
                        font-size: 20px;
                        padding-top: 8px;
                    }
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 22px;
                        height: 22px;
                        left: 20px;
                        top: 35px;
                        border-radius: 50%;
                        border: 1px solid #939393;
                        background-repeat: no-repeat;
                    }
                    .inline-provider {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                    }
                }
                .form-check-input:checked + .form-check-label {
                    border: 2px solid $emeraldGreen;

                    &::before {
                        width: 24px;
                        height: 24px;
                        border-color: transparent;
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM9.62575 15.1253C10.1771 15.1253 10.6954 14.9086 11.0831 14.5162L16.0981 9.246C16.6343 8.70278 16.6343 7.82484 16.0981 7.28162C15.5605 6.73977 14.6902 6.73977 14.1541 7.28162L9.62575 12.0621L7.84745 10.0316C7.31125 9.4898 6.44097 9.4898 5.90341 10.0316C5.36584 10.5749 5.36584 11.4528 5.90341 11.996L8.16703 14.5162C8.55749 14.9086 9.07306 15.1253 9.62575 15.1253Z' fill='%23176E54'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
    }
    .inline-form-groups {
        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 0px;
        }
    }
}

.checkout-payment {
    .payment-options {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 28px;

        .payment-option {
            list-style: none;
            width: 100%;

            .form-check {
                position: relative;
                padding: 0px;

                .form-check-input {
                    position: absolute;
                    clip: rect(0,0,0,0);
                    pointer-events: none;
                }
                .form-check-label {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 24px 24px 24px 68px;
                    gap: 0px;
                    min-height: 94px;
                    background: #FFFFFF;
                    border: 1px solid #999999;
                    border-radius: 4px;
                    box-sizing: border-box;

                    h5 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: #444444;
                        margin: 0;
                    }
                    h3 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 44px;
                        color: $navyBlue;
                        margin: 0;
                    }
                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #444444;
                        margin: 4px 0 0 0;
                    }
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 22px;
                        height: 22px;
                        left: 20px;
                        top: 35px;
                        border-radius: 50%;
                        border: 1px solid #939393;
                        background-repeat: no-repeat;
                    }
                    .inline-provider {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                    }
                }
                .form-check-input:checked + .form-check-label {
                    border: 2px solid $emeraldGreen;

                    &::before {
                        width: 24px;
                        height: 24px;
                        border-color: transparent;
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM9.62575 15.1253C10.1771 15.1253 10.6954 14.9086 11.0831 14.5162L16.0981 9.246C16.6343 8.70278 16.6343 7.82484 16.0981 7.28162C15.5605 6.73977 14.6902 6.73977 14.1541 7.28162L9.62575 12.0621L7.84745 10.0316C7.31125 9.4898 6.44097 9.4898 5.90341 10.0316C5.36584 10.5749 5.36584 11.4528 5.90341 11.996L8.16703 14.5162C8.55749 14.9086 9.07306 15.1253 9.62575 15.1253Z' fill='%23176E54'/%3E%3C/svg%3E%0A");
                    }
                }
            }
            .payment-provider-fields {
                width: 100%;

                .form-description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #444444;
                    margin: 28px 0 16px 0;
                }
                .card-field {
                    background-image: url("./../images/checkout/card-field-bg.png");
                    background-repeat: no-repeat;
                    background-position: right 20px center;
                }
                .sub-payment-options {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    list-style: none;
                    padding: 0;
                    margin-top: 28px;

                    .payment-option {
                        .form-check-label {
                            min-height: auto;
                            padding: 14px 14px 14px 68px;

                            h4 {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                color: #444444;
                                margin: 0;
                            }
                            p {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 18px;
                                color: #444444;
                                margin: 0;
                            }
                            &::before {
                                top: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.checkout-review {
    padding: 44px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 44px;        

    .payment-details {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 16px;
        gap: 12px;        
        border: 1px solid #EDEDED;
        border-radius: 4px;
        margin: 0;

        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: $navyBlue;
            margin: 0;
        }
        hr {
            display: block;
            width: 100%;
            height: 1px;
            border: 1px solid #EDEDED;
            margin: 0;
            
            &.dashed {
                border: 1px dashed #EDEDED;
            }
        }
        .payment-method-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 16px;
            width: 100%;

            .texts {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                flex-grow: 1;

                span {
                    font-family: $fontMontserrat;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #444444;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $navyBlue;
                    margin: 0;
                }
            }
        }
        .payment-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 4px;
            width: 100%;

            span {
                font-family: $fontMontserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #444444;
                flex-grow: 1;
            }
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: right;
                color: $navyBlue;
                margin: 0;
            }
        }
        .btn {
            display: block;
            width: 100%;

            &:disabled {
                border-color: #999999;
                background-color: #999999;
            }
        }
    }
    .order-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        width: 100%;

        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: #444444;
            margin: 0;
        }
        .order-items-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 12px;
            width: 100%;
            margin: 0;

            .order-item {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 24px;

                .texts {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 2px;
                    width: 100%;
                    flex-grow: 1;

                    h4 {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: $navyBlue;
                        margin: 0;
                    }
                    .price {
                        font-family: $fontMontserrat;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 30px;
                        color: $navyBlue;
                    }
                    .description {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        color: #444444;
                        margin: 0;
                    }
                }
                .order-thumbnail {
                    width: 64px;
                    height: 64px;
                    
                    img {
                        width: 64px;
                        height: 64px;
                        border-radius: 4px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    .shipping-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        width: 100%;

        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: #444444;
            margin: 0;
        }
        .shipping-row {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 4px;

            span {
                font-family: $fontMontserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #444444;
            }
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $navyBlue;
                margin: 0;
            }
        }
    }
}

.payment-complete-modal {
    .modal-dialog {
        max-width: 612px;

        .modal-content {
            padding: 66px 114px;

            .modal-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0px;
                gap: 32px;
                text-align: center;

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 28px;
                    text-align: center;
                    color: #242424;
                    margin-bottom: 32px;
                }
            }
            .modal-footer {
                padding: 0;
            }
        }
    }
}