.modal {
    .modal-content {
        background: #FFFFFF;
        border-radius: 4px;
        border: none;

        .modal-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 24px;
            gap: 16px;
            border-bottom: 1px solid #F2F2F2;

            .modal-title {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: $navyBlue;
            }
            .btn-close {
                padding: 0px;
                width: 24px;
                height: 24px;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_690_13254)'%3E%3Cpath d='M12.0002 10.5867L16.9502 5.63672L18.3642 7.05072L13.4142 12.0007L18.3642 16.9507L16.9502 18.3647L12.0002 13.4147L7.05023 18.3647L5.63623 16.9507L10.5862 12.0007L5.63623 7.05072L7.05023 5.63672L12.0002 10.5867Z' fill='%2317253F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_690_13254'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                background-size: 100%;
                opacity: 1;
            }
        }
        .modal-body {
            padding: 30px 24px;
        }
        .modal-footer {
            border: none;

            .btn {
                padding: 12px;
                width: auto;
                flex-grow: 1;
            }
        }
    }
}