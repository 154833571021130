.btn {
    @include media-breakpoint-down(md) {
        padding: 16px 32px;
    }
}

.btn-md {
    font-family: $fontMontserrat;
    font-size: 18px;
    font-weight: 700;
    padding: 8px 16px;
}

.btn-primary {
    &:hover {
        background-color: tint-color($navyBlue, 10%);
    }
}

.btn-light {
    color: $navyBlue;

    &:hover {
        background-color: shade-color($white, 10%);
    }
}

.btn-ncs,
.btn-gold, 
.btn-mint, 
.btn-mustard,
.btn-carrot {
    color: #FFF;
}

.btn-ncs,
.btn-gold, 
.btn-mint, 
.btn-mustard,
.btn-carrot,
.btn-outline-ncs,
.btn-outline-gold,
.btn-outline-mustard,
.btn-outline-mint,
.btn-outline-carrot {
    &.active,
    &:active,
    &:hover,
    &:focus-visible,
    :not(.btn-check) + &:active,
    &:first-child:active,
    &.show {
        color: #FFF;
    }
}

.input-group-append {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    .btn {
        position: relative;
        z-index: 2;
        border-radius: 5px;
        margin: 0;
        margin-right: -$btn-border-width;
        padding: 0 1.5rem;
        min-width: auto;
        border-radius: 0;

        &:last-of-type {
            margin-right: 0;
            border-top-right-radius: $btn-border-radius;
            border-bottom-right-radius: $btn-border-radius;
        }
    }
}