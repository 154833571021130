@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&family=Open+Sans:wght@400;500;600;700&family=Work+Sans:wght@400;500;600;700&display=swap');

$fontMontserrat: 'Montserrat', sans-serif;
$fontOpenSans: 'Open Sans', sans-serif;
$fontWorkSans: 'Work Sans', sans-serif;

h1, h2, h3, h4, h5, h6, .heading {
    display: block;
    font-family: $fontMontserrat;
    letter-spacing: -0.02em;
    color: $navyBlue;
    margin-top: 0;
    margin-bottom: .5rem;
}

p {
    font-family: $fontMontserrat;
}