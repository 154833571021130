.waitlist {
    background-image: url('./../images/navyblue-osmium-2-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $navyBlue;
    border-radius: 8px;

    .waitlist-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        padding: 24px 0;
        margin: 0px;
        background: $white;
        border-radius: 0px;

        @include media-breakpoint-up(md) {
            padding: 24px;
            margin: 40px 40px 64px 40px;
            border-radius: 8px;
        }
        @include media-breakpoint-up(lg) {
            margin: 40px 56px 64px 56px;
        }

        .form-group {
            width: 100%;
            margin: 0;
        }
    }
}